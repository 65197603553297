import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {BlogContainer} from "../../components/blog/container";
import {BlogMDWrapper} from "../../components/blog/md-wrapper";
import {MarkdownViewer} from "../../components/markdown-viewer";
import {AnchorLink} from "../../components/menu";

const markdown = `

`;

const elements = [
    {
        title: 'Software Development Lifecycle',
        components: [
            {
                title: 'Plan',
                subtitle: 'all the way through the project doing these things',
                sub_components: [
                    {
                        title: 'Requirements',
                        success: [
                            {
                                title: 'PreRDs for features, signoffs along the way'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'PreRD Template'
                            }
                        ]
                    },
                    {
                        title: 'Large Projects',
                        success: [
                            {
                                title: 'Break large projects down into separate planning phase'
                            },
                            {
                                title: 'define success measures and really measure them'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'Large Project Template'
                            },
                            {
                                title: 'Planning Template'
                            }
                        ]
                    },
                    {
                        title: 'Small Projects',
                        artifacts: [
                            {
                                title: 'Small Project Template'
                            },
                        ]
                    },
                    {
                        title: 'Work Breakdown Structure',
                    },
                ]
            },
            {
                title: 'Design',
                sub_components: [
                    {
                        title: 'High-level Design',
                        success: [
                            {
                                title: 'User Experience'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'UX Wireframes'
                            }
                        ]
                    },
                    {
                        title: 'Low-level Design',
                        success: [
                            {
                                title: 'Final Designs'
                            }
                        ],
                        tools: [
                            {
                                title: 'Figma / Sketch'
                            }
                        ]
                    },
                    {
                        title: 'User Testing',
                        success: [
                            {
                                title: 'quick feedback loops'
                            },
                            {
                                title: 'different types of testing'
                            }
                        ],
                        tools: [
                            {
                                title: 'usertesting.com'
                            },
                            {
                                title: 'a/b testing'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'creating a compelling a/b test'
                            }
                        ]
                    },
                    {
                        title: 'Design System',
                        success: [
                            {
                                title: 'Design Tokens'
                            },
                            {
                                title: 'accessibility best practices'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'Component Libraries'
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Develop',
                sub_components: [
                    {
                        title: 'Environment',
                        success: [
                            {
                                title: 'staging, dev, production'
                            }
                        ]
                    },
                    {
                        title: 'Prototyping',
                    },
                    {
                        title: 'Architecture',
                        success: [
                            {
                                title: 'Build for abstraction'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'system maps'
                            }
                        ]
                    },
                    {
                        title: 'Remote Configs',
                        success: [
                            {
                                title: 'build for independent testing'
                            }
                        ]
                    },
                    {
                        title: 'Documentation',
                        success: [
                            {
                                title: 'inline code comments'
                            },
                            {
                                title: 'high level documentation including diagrams'
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Test',
                sub_components: [
                    {
                        title: 'Product Testing',
                        success: [
                            {
                                title: 'Each product has a test suite'
                            },
                            {
                                title: 'Understand audience'
                            },
                            {
                                title: 'Perform root-cause analysis (RCAs)'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'Test Suite Template'
                            },
                            {
                                title: 'RCA Template'
                            }
                        ]
                    },
                    {
                        title: 'Feature Testing',
                        success: [
                            {
                                title: 'Requirements for a feature covered thru acceptance tests'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'Bug Bash Template'
                            }
                        ]
                    },
                    {
                        title: 'Testing Automation',
                        success: [
                            {
                                title: 'functional coverage'
                            }
                        ]
                    },
                    {
                        title: 'Anomoly Detection',
                        success: [
                            {
                                title: 'baselines'
                            },
                            {
                                title: 'alerts'
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        title: 'Organization Foundation',
        components: [
            {
                title: 'Strategy',
                sub_components: [
                    {
                        title: 'Prioritization',
                        success: [
                            {
                                title: 'Scoring Criteria'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'Roadmap'
                            },
                            {
                                title: 'Feature Requests'
                            }
                        ]
                    },
                    {
                        title: 'Structure',
                        success: [
                            {
                                title: 'Teams & Roles'
                            }
                        ]
                    },
                    {
                        title: 'User Guides',
                    }
                ]
            },
            {
                title: 'DevOps',
                sub_components: [
                    {
                        title: 'Git Process',
                        success: [
                            {
                                title: 'Products use similar standardized process'
                            },
                            {
                                title: 'branches for features, all things are tested'
                            },
                            {
                                title: 'one stable branch (master or main) where tested features cmoe in'
                            }
                        ]
                    },
                    {
                        title: 'Code Reviews',
                        success: [
                            {
                              title: 'defined reviewer'
                            },
                            {
                                title: 'Code Style and linters'
                            },
                            {
                                title: 'videos on features with context'
                            },
                            {
                                title: 'quick turnaround'
                            },
                        ],
                        artifacts: [
                            {
                                title: 'PR Submission Template'
                            },
                            {
                                title: 'PR Checklist',
                                link: '/blog/pragmatic-pull-requests'
                            }
                        ]
                    },
                    {
                        title: 'CI/CD',
                        success: [
                            {
                                title: 'Build for each PR'
                            }
                        ]
                    },
                    {
                        title: 'Performance Monitoring',
                        success: [
                            {
                                title: 'dashboards for latency'
                            },
                            {
                                title: 'alerts'
                            }
                        ]
                    },
                    {
                        title: 'Release Management',
                        success: [
                            {
                                title: 'Each product can launch updates on its own'
                            },
                            {
                                title: 'Regular release date for each product'
                            },
                            {
                                title: 'What happens when for releases'
                            }
                        ],
                        prereqs: [
                            {
                                title: 'standard git process across products'
                            },
                            {
                                title: 'Builds for each pull request'
                            },
                            {
                                title: 'separate feature and product testing'
                            }
                        ],
                        tools: [
                            {
                                title: 'Dark Launch Features'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'Product Release Pages'
                            },
                            {
                                title: 'Release Rollup Calendar'
                            },
                            {
                                title: 'Release Roles'
                            }
                        ]
                    },
                    {
                        title: 'Developer Docs',
                    }
                ]
            },
            {
                title: 'People',
                sub_components: [
                    {
                        title: 'Roles & Responsibilities',
                        success: [
                            {
                                title: 'distinct and clear separations of duties within teams, even if people have multiple roles'
                            }
                        ]
                    },
                    {
                        title: 'Skills & Training',
                        artifacts: [
                            {
                                title: 'Database of people and their skills'
                            }
                        ]
                    },
                    {
                        title: 'Team building',
                    },
                    {
                        title: 'Personal Growth',
                        success: [
                            {
                                title: 'Everyone has 1:1s'
                            },
                            {
                                title: '360 reviews'
                            }
                        ]
                    },
                    {
                        title: 'Interviewing',
                        success: [
                            {
                                title: 'Write better JDs'
                            },
                            {
                                title: 'Raise the Bar'
                            }
                        ],
                        artifacts: [
                            {
                                title: 'Roles overview'
                            },
                            {
                                title: 'Questions Database'
                            },
                            {
                                title: 'Debrief Document'
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Metrics',
                sub_components: [
                    {
                        title: 'KPIs',
                    },
                    {
                        title: 'Dashboards',
                    }
                ]
            }
        ]
    }
];

function SubComponentItem({items,title='Definition of Success'}) {
    if(!items) {
        return null;
    }
    return <div>
        <div className="text-sm font-medium text-gray-700">
            {title}
        </div>
        <ul className="list-disc text-sm text-gray-600 pl-6 pt-1">
            {items.map((si, n) => {
                return <li className="" key={n}>
                    {si.link? <AnchorLink to={si.link} className="underline">
                        {si.title}
                        </AnchorLink>:si.title}
                </li>
            })}
        </ul>
    </div>
}

export default function BlogReleaseTrains() {

    return (
        <Page title="Elements of an effective Engineering Organization" menu>
            <Header subtitle="Organizational Design" title='Elements of an effective Engineering Organization'
            />
            <div className="p-8 bg-white">
                <div className="prose mx-auto">
                    Principles: Autonomy, Abstraction, and Automation
                </div>
            </div>
            <div className="p-8 bg-white">
                <div className="prose w-full max-w-4xl mx-auto space-y-2">
                    {elements.map((el, k) => {
                        return <div key={k}>
                            <div
                                className="p-2 justify-center bg-gray-100 text-sm font-medium text-center flex items-center rounded-t-md text-gray-600">{el.title}</div>
                            <div
                                className="grid grid-cols-4 gap-1 p-4 border-l border-r border-b border-gray-100 rounded-b-md">
                                {el.components.map((comp, m) => {

                                    return <div key={m}>
                                        <div className="text-sm font-medium text-gray-700 mb-1">{comp.title}</div>
                                        <div className="space-y-1">
                                            {comp.sub_components.map((sc, t) => {

                                                return <a href={`#${el.title}-${comp.title}-${sc.title}`}
                                                          className="block text-sm text-gray-500" key={t}>
                                                    {sc.title}
                                                </a>
                                            })}
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            <div className="p-8">
                <div className="container mx-auto space-y-4">
                    {elements.map((el, k) => {
                        return <div key={k}>
                            <div className="text-gray-800 text-3xl pt-4 pb-1 font-bold">{el.title}</div>
                            <div className="space-y-4">
                                {el.components.map((comp, m) => {
                                    return <div key={m}>
                                        <div className="py-2">
                                            <div className="text-gray-700 text-xl font-bold">{comp.title}</div>
                                            {comp.subtitle && <div className="text-base text-gray-700">
                                                {comp.subtitle}
                                            </div>}
                                        </div>
                                        <div className="grid grid-cols-2 gap-4">
                                            {comp.sub_components.map((sc, t) => {
                                                return <div id={`${el.title}-${comp.title}-${sc.title}`}
                                                            className="border space-y-4 border-gray-200 px-4 py-3 rounded-md "
                                                            key={t}>
                                                    <div>
                                                        <div className="text-gray-700 text-lg font-semibold">
                                                            {sc.title}
                                                        </div>
                                                        {sc.subtitle && <div className="text-gray-700 text-sm">
                                                            {sc.subtitle}
                                                        </div>}
                                                    </div>
                                                    <SubComponentItem title="Definition of Success" items={sc.success} />
                                                    <SubComponentItem title="Tools & Frameworks" items={sc.tools} />
                                                    <SubComponentItem title="Prerequisites" items={sc.prereqs} />
                                                    <SubComponentItem title="Artifacts" items={sc.artifacts} />
                                                </div>
                                            })}
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            <BlogContainer>
                <BlogMDWrapper>
                    <MarkdownViewer source={markdown}></MarkdownViewer>
                </BlogMDWrapper>
            </BlogContainer>
        </Page>
    )
}